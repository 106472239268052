import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRowsHead = []
        if (search.registration_for) {
          const rowItem = [
            { text: vm.$t('globalTrans.type'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.user_type_bn : search.user_type, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.fiscal_year) {
          const rowItem = [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fiscal_year_bn : search.fiscal_year, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.org_name) {
          const rowItem = [
            { text: vm.$t('elearning_config.organization'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.org_name_bn : search.org_name, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_type) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_type'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_type_bn : search.training_type, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_category) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_category'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_category_bn : search.training_category, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_title) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_title_bn : search.training_title, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['45%', '5%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.trainee_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.disciplinary_action'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.total_marks'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.attain_marks'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        if (data.length) {
          data.forEach((info, index) => {
            allRows.push([
              { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
              { text: i18n.locale === 'bn' ? info.org_bn : info.org, alignment: 'center', style: 'td' },
              { text: i18n.locale === 'bn' ? info.training_title_bn : info.training_title, alignment: 'center', style: 'td' },
              { text: i18n.locale === 'bn' ? info.personal_info_name_bn : info.personal_info_name, alignment: 'center', style: 'td' },
              { text: i18n.locale === 'bn' ? info.disciplinary_action_bn : info.disciplinary_action, alignment: 'center', style: 'td' },
              { text: info.evaluation_marks ? vm.$n(info.evaluation_marks) : '', alignment: 'center', style: 'td' },
              { text: info.attain_marks ? vm.$n(info.attain_marks) : '', alignment: 'center', style: 'td' }
            ])
          })
        } else {
          allRows.push([
            { text: vm.$t('globalTrans.noDataFound'), alignment: 'center', style: 'td', colSpan: 7 },
            { },
            { },
            { },
            { },
            { },
            { }
          ])
        }
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
        //   address: {
        //     fontSize: 9,
        //     margin: [0, -10, 0, 0]
        //   },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
