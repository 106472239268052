<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.performance_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="User Type" vid="registration_for" rules="required|min_value:1">
                              <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="registration_for"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('globalTrans.type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-radio-group
                              v-model="search.registration_for"
                              :options="paymentType"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-radio-group>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" >
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Organization"  vid="org_id" >
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                        <ValidationProvider name="Training Type"  vid="training_type_id" >
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_type_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_type')}}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.training_type_id"
                              :options="trainingTypeList"
                              id="training_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" >
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" >
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col cols="6" class="text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('tpm_report.performance_report') }}</h4>
              </template>
              <template v-slot:headerAction>
                <b-button class="btn_add_new" @click="pdfExport">
                  <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                </b-button>
              </template>
              <template v-slot:body>
                <b-overlay :show="loading">
                  <b-row mt-5>
                    <b-col md="12" class="table-responsive">
                      <div style="border: 2px solid;margin:10px;padding:10px">
                        <b-row>
                          <b-col>
                            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                              <template v-slot:projectNameSlot>
                                {{ }}
                              </template>
                              {{ $t('tpm_report.performance_report') }}
                            </list-report-head>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="text-center">
                              <table style="width:100%;color:black;">
                                <tr v-if="search.registration_for">
                                  <td align="right" style="width:45%">{{ $t('globalTrans.type') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.user_type_bn : search.user_type }}</td>
                                </tr>
                                <tr v-if="search.fiscal_year">
                                  <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                </tr>
                                <tr v-if="search.org_name">
                                  <td align="right" style="width:45%">{{ $t('elearning_config.organization') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.org_name_bn : search.org_name }}</td>
                                </tr>
                                <tr v-if="search.training_type">
                                  <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                </tr>
                                <tr v-if="search.training_category">
                                  <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                </tr>
                                <tr v-if="search.training_title">
                                  <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                </tr>
                              </table>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                              <b-thead>
                                <b-tr>
                                  <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                  <b-th style="width:10%" class="text-center">{{ $t('elearning_config.organization') }}</b-th>
                                  <b-th style="width:10%" class="text-center">{{ $t('elearning_config.training_title') }}</b-th>
                                  <b-th class="text-center">{{$t('elearning_tim.trainee_name')}}</b-th>
                                  <b-th class="text-center">{{$t('tpm_report.disciplinary_action')}}</b-th>
                                  <b-th class="text-right">{{ $t('elearning_tim.total_marks') }}</b-th>
                                  <b-th class="text-right">{{ $t('elearning_tim.attain_marks') }}</b-th>
                                </b-tr>
                              </b-thead>
                              <b-tbody>
                                <template v-if="datas.length">
                                  <b-tr v-for="(info, index) in datas" :key="index">
                                    <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.org_bn : info.org }}</b-td>
                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.training_title_bn : info.training_title }}</b-td>
                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.personal_info_name_bn : info.personal_info_name }}</b-td>
                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.disciplinary_action_bn : info.disciplinary_action }}</b-td>
                                    <b-td class="text-right">{{ info.evaluation_marks ? $n(info.evaluation_marks) : '' }}</b-td>
                                    <b-td class="text-right">{{ info.attain_marks ? $n(info.attain_marks) : '' }}</b-td>
                                  </b-tr>
                                </template>
                                <template v-else>
                                  <b-tr>
                                    <b-td align="center" colspan="7">{{ $t('globalTrans.noDataFound') }}</b-td>
                                  </b-tr>
                                </template>
                              </b-tbody>
                            </b-table-simple>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { performanceReport, personalDataList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
import Store from '@/store'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead
    },
    created () {
      this.personalDataList()
    },
    data () {
        return {
          search: {
            registration_for: 1,
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            fiscal_year_id: 0,
            fiscal_year: '',
            org_name: '',
            training_type: '',
            training_category: '',
            training_title: ''
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          personalInfoList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          showData: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    computed: {
      paymentType: function () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      loading () {
        return this.$store.state.commonObj.loading
      }
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      async personalDataList () {
            const params = {}
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, personalDataList, params)
            if (result.success) {
                this.personalInfoList = result.data
            } else {
                this.personalInfoList = []
            }
        },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      pdfExport () {
          const reportTitle = this.$t('tpm_report.performance_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
      },
      async searchData () {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        this.showData = true
        const userType = this.paymentType.find(item => item.value === parseInt(this.search.registration_for))
        this.search.user_type = userType?.text_en
        this.search.user_type_bn = userType?.text_bn
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === this.search.org_id)
        this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
        this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id)
        this.search.fiscal_year = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_en : ''
        this.search.fiscal_year_bn = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_bn : ''

        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
        this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
        this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

        const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
        this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
        this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

        const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
        this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
        this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''

        const result = await RestApi.getData(trainingElearningServiceBaseUrl, performanceReport, this.search)
        if (result.success) {
          Store.commit('mutateCommonProperties', {
            loading: false
          })
          if (result.data) {
            this.datas = this.getCustomDataList(result.data)
          }
        } else {
          this.datas = []
          Store.commit('mutateCommonProperties', {
            loading: false
          })
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          const personalInfoObj = this.personalInfoList.find(doc => doc.value === parseInt(item.personal_info_id))
          const personalInfoData = {
            personal_info_name: personalInfoObj?.name,
            personal_info_name_bn: personalInfoObj?.name_bn
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData, personalInfoData)
        })
        return listData
      }
    }
}
</script>
